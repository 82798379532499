import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { ReactNode } from 'react';
import GlobalErrorAlert from './alerts/GlobalErrorAlert/GlobalErrorAlert';
import GlobalInfoAlert from './alerts/GlobalInfoAlert/GlobalInfoAlert';

type Props = {
  children: ReactNode;
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#990101',
    },
  },
});

export default function App({ children }: Props) {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ height: '100%' }}>{children}</Box>
      <Box>
        <GlobalErrorAlert />
        <GlobalInfoAlert />
      </Box>
    </ThemeProvider>
  );
}
