import React, { useState, useEffect, useContext } from 'react';
import { Amplify, Hub, HubCapsule } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import { parseIdToken, User } from '../../../domain/User';
import UserContext from '../../../context/UserContext';
import ConfigContext from '../../../context/ConfigContext';
import axios from 'axios';
import { traceIdInterceptor } from '../../../services/traceIdInterceptor';

export default function AmplifyProvider({ children }: React.PropsWithChildren<{}>) {
  const [currentUser, setCurrentUser] = useState<null | User>(null);
  const [loaded, setLoaded] = useState(false);
  const config = useContext(ConfigContext);

  const updateCurrentUser = (user?: User) => {
    if (user) {
      setCurrentUser(user);
      setLoaded(true);
      return;
    }

    Auth.currentAuthenticatedUser()
      .then((currentAuthenticatedUser) => {
        const newUser = {
          email: currentAuthenticatedUser.attributes.email,
          firstName: currentAuthenticatedUser.attributes?.given_name ?? '',
          lastName: currentAuthenticatedUser.attributes?.family_name ?? '',
          role: currentAuthenticatedUser.attributes?.['custom:role'] ?? '',
          organisations: parseIdToken(currentAuthenticatedUser.signInUserSession.getIdToken().getJwtToken()),
          superUser: currentAuthenticatedUser.attributes?.['custom:super_user'] == 'true',
        };

        setCurrentUser(newUser);
        setLoaded(true);
      })
      .catch(() => {
        setCurrentUser(null);
        setLoaded(true);
      });
  };

  const onHubCapsule = (capsule: HubCapsule) => {
    const { channel, payload } = capsule;
    if (channel === 'auth' && payload.event !== 'signIn') {
      updateCurrentUser(undefined);
    }
  };

  useEffect(() => {
    Amplify.configure({
      Auth: {
        region: config.amplify.region,
        userPoolId: config.amplify.auth.userPoolId,
        userPoolWebClientId: config.amplify.auth.userPoolWebClientId,
      },
      API: {
        endpoints: [
          {
            name: 'cftest',
            endpoint: config.amplify.endpoints.cftest,
            region: config.amplify.region,
            authorizationType: 'AMAZON_COGNITO_USER_POOLS',
          },
          {
            name: 'qofopt',
            endpoint: config.amplify.endpoints.qofopt,
            region: config.amplify.region,
            authorizationType: 'AMAZON_COGNITO_USER_POOLS',
          },
        ],
      },
    });

    axios.interceptors.request.use(traceIdInterceptor);

    updateCurrentUser(undefined);
    Hub.listen('auth', onHubCapsule);
  }, []);

  return (
    <UserContext.Provider
      value={{
        user: currentUser,
        updateCurrentUser,
        loaded: loaded,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
