import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobalInfo, setGlobalInfo } from '../../../redux/rootReducer';

export default function GlobalInfoAlert() {
  const info = useSelector(selectGlobalInfo);
  const [open, setOpen] = useState(info !== null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (info) {
      setOpen(true);
    }
  }, [info]);

  const close = () => {
    setOpen(false);
  };
  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      onClose={close}
      TransitionProps={{
        onExited: () => {
          // Only unset the message after the snackbar disappears completely
          dispatch(setGlobalInfo(null));
        },
      }}
    >
      <Alert severity="info" variant="filled" onClose={close}>
        {info}
      </Alert>
    </Snackbar>
  );
}
