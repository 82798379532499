import { Backdrop, Box, Button, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Invitation } from '../../../domain/Invitation';
import { useDialog } from '../../../hooks/useDialog/useDialog';
import { setGlobalError, setGlobalInfo } from '../../../redux/rootReducer';
import * as Api from '../../../services/Api';
import { ConfirmationDialog, ConfirmationDialogConfig } from '../../shared/ConfirmationDialog/ConfirmationDialog';
import { InviteDialog } from '../InviteDialog/InviteDialog';
import OrganisationInvitationsTable from '../OrganisationInvitationsTable/OrganisationInvitationsTable';

interface Props {
  organisation: string;
}

export default function OrganisationInvitations({ organisation }: Props) {
  const [loading, setLoading] = useState(true);
  const [invitations, setInvitations] = useState<Invitation[]>([]);

  const dispatch = useDispatch();

  const { open: openDeleteConfirmationDialog, connector: confirmationDialogConnector } =
    useDialog<ConfirmationDialogConfig>();
  const { open: openInvitationDialog, connector: invitationDialogConnector } = useDialog<{}, string>();

  useEffect(() => {
    Api.loadOrganisationInvitations(organisation)
      .then((newInvitations) => {
        setInvitations(newInvitations);
      })
      .catch((error) => {
        dispatch(setGlobalError(`Unable to load invitations for organisation ${organisation}`, error));
      })
      .finally(() => setLoading(false));
  }, []);

  const deleteInvitation = (email: string) => {
    return Api.deleteInvitation(email, organisation)
      .then(() => {
        setInvitations(invitations.filter((invitation) => invitation.email !== email));
        dispatch(setGlobalInfo(`Deleted invitation for ${email}`));
      })
      .catch((error) => {
        dispatch(setGlobalError(`Unable to delete invitation for ${email}`, error));
      });
  };

  const askBeforeDeletingInvitation = (email: string) => {
    openDeleteConfirmationDialog({
      title: 'Delete invitation',
      text: `Are you sure you want to delete the invitation for ${email}?`,
    }).then(() => deleteInvitation(email));
  };

  const onInvite = (email: string) =>
    Api.invite(email, organisation)
      .then((invitation) => {
        setInvitations(invitations.filter((inv) => inv.email !== invitation.email).concat(invitation));
        dispatch(setGlobalInfo(`Invited ${email}`));
      })
      .catch((error) => {
        dispatch(setGlobalError(`Unable to invite ${email}`, error));
        throw error;
      });

  const openInviteDialog = () => {
    openInvitationDialog({}).then((email) => onInvite(email));
  };

  if (loading) {
    return (
      <Backdrop open={loading} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress data-testid="spinner" />
      </Backdrop>
    );
  }

  return (
    <>
      <OrganisationInvitationsTable invitations={invitations} deleteInvitation={askBeforeDeletingInvitation} />

      <Box mt={2} textAlign="right">
        <Button variant="contained" onClick={openInviteDialog}>
          Invite user...
        </Button>

        <InviteDialog connector={invitationDialogConnector} />
        <ConfirmationDialog connector={confirmationDialogConnector} />
      </Box>
    </>
  );
}
