import React, { useState } from 'react';

import Button from '@mui/material/Button';

import { Auth } from '@aws-amplify/auth';

import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';

import { FormControl, Stack } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setGlobalError } from '../../../../redux/rootReducer';
import { FieldError, FieldValues, useForm } from 'react-hook-form';
import EmailField from '../../../shared/EmailField/EmailField';
import { URLS } from '../../../../util/urls';
import { LoadingButton } from '@mui/lab';

interface Props {
  onCodeRequested: (email: string) => void;
}

export default function RequestCodeForm({ onCodeRequested }: Props) {
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const updateErrorMessage = (errorMessage: null | string) => {
    dispatch(setGlobalError(errorMessage));
  };

  const sendResetCode = ({ email }: FieldValues) => {
    setSubmitting(true);
    Auth.forgotPassword(email)
      .then(() => {
        onCodeRequested(email);
      })
      .catch((err) => {
        if (err.message === 'E-mail not found.') {
          updateErrorMessage('E-mail not found');
        } else {
          updateErrorMessage(`Unable to send the account recovery code: ${err.message}`);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <form>
      <FormControl sx={{ rowGap: '24px', width: '290px' }}>
        <>
          <EmailField
            id="email"
            name={'email'}
            control={control}
            error={errors.email as FieldError}
            rules={{
              required: 'E-mail is required',
              invalid: 'Please enter a valid e-mail',
            }}
            label="E-mail"
            autoFocus={true}
            required
          />

          <Stack direction="row" spacing={1} justifyContent="flex-end">
            <Link component={RouterLink} to={URLS.LOGIN}>
              <Button color="primary">Back</Button>
            </Link>

            <LoadingButton
              data-testid="send-code"
              variant="contained"
              color="primary"
              loading={submitting}
              onClick={handleSubmit(sendResetCode)}
            >
              Send code
            </LoadingButton>
          </Stack>
        </>
      </FormControl>
    </form>
  );
}
