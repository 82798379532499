import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import sourceMapSupport from 'source-map-support';
import { Root } from './components/Root';
import config from './config/config';
import * as logger from './logging/logger';
import { store } from './redux/store';

sourceMapSupport.install({
  environment: 'browser',
});

const renderApp = () => createRoot(document.getElementById('root')!).render(<Root store={store} />);

document.addEventListener('DOMContentLoaded', () => {
  logger.init(config);
  renderApp();
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./components/Root', () => renderApp());
}
