import jwt_decode from 'jwt-decode';

export interface User {
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  organisations: UserMembership[];
  superUser: boolean;
}

export interface UserMembership {
  name: string;
  permissions: string[];
}

export function parseIdToken(idToken: string): UserMembership[] {
  const decodedJwt = jwt_decode(idToken);
  try {
    return JSON.parse((decodedJwt as any).organisations);
  } catch (error) {
    return [];
  }
}

export function isAdminAt(user: User, organisation: string): boolean {
  return (
    (user.superUser || user.organisations.find((org) => org.name === organisation)?.permissions?.includes('admin')) ??
    false
  );
}
