import React from 'react';

import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router';
import { Grid } from '@mui/material';
import RequestCodeForm from '../../components/auth/resetpassword/RequestCodeForm/RequestCodeForm';
import ResetPasswordForm from '../../components/auth/resetpassword/ResetPasswordForm/ResetPasswordForm';
import { useDispatch } from 'react-redux';
import { setGlobalInfo } from '../../redux/rootReducer';
import { URLS } from '../../util/urls';

export default function ResetPasswordPage() {
  const [email, setEmail] = React.useState('');
  const [codeSent, setCodeSent] = React.useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onCodeRequested = (requestEmail: string) => {
    setEmail(requestEmail);
    setCodeSent(true);
    dispatch(
      setGlobalInfo(
        `An account recovery code has been sent to ${requestEmail}. Please enter it above, together with a new password.`
      )
    );
  };

  const onPasswordChanged = () => {
    dispatch(
      setGlobalInfo('Your new password has been successfully set - you are being redirected back to the login page.')
    );

    setTimeout(() => {
      navigate(URLS.LOGIN);
    }, 2000);
  };

  return (
    <Grid container spacing={0} justifyContent="center" alignContent="center" direction="column">
      <Grid item mt={20}>
        <Typography variant="h5" gutterBottom>
          Password reset
        </Typography>

        {codeSent ? (
          <ResetPasswordForm email={email} onPasswordChanged={onPasswordChanged} />
        ) : (
          <RequestCodeForm onCodeRequested={onCodeRequested} />
        )}
      </Grid>
    </Grid>
  );
}
