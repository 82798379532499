import { LoadingButton } from '@mui/lab';
import { Dialog, DialogTitle, DialogContent, FormControl, DialogActions, Button, Box, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FieldError, FieldValues, useForm } from 'react-hook-form';
import { UseDialogConnector } from '../../../hooks/useDialog/useDialog';
import TextField from '../../shared/TextField/TextField';

export function ApplyDialog({ connector }: { connector: UseDialogConnector<{}, string> }) {
  const [open, setOpen] = useState(connector.config !== null);
  const [submitting, setSubmitting] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  useEffect(() => {
    if (connector.config) {
      setOpen(true);
    }
  }, [connector.config]);

  const close = () => {
    reset({ organisation: '' });
    setOpen(false);
  };

  const apply = ({ organisation }: FieldValues) => {
    setSubmitting(true);

    return connector
      .submit(organisation)
      .then(close)
      .catch(() => {})
      .finally(() => {
        setSubmitting(false);
      });
  };

  if (!connector.config) {
    return <></>;
  }

  return (
    <>
      <Dialog open={open} onClose={close}>
        <DialogTitle>Join organisation</DialogTitle>
        <DialogContent>
          <form>
            <FormControl sx={{ marginTop: '8px', width: '290px' }}>
              <TextField
                id="organisation"
                name={'organisation'}
                control={control}
                error={errors.organisation as FieldError}
                rules={{
                  required: 'The organisation is required',
                }}
                label="Organisation"
                required
                autoFocus={open}
              />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={1}>
            <Button variant="outlined" onClick={close}>
              Cancel
            </Button>
            <Box sx={{ position: 'relative' }}>
              <LoadingButton onClick={handleSubmit(apply)} variant="contained" data-testid="apply" loading={submitting}>
                Apply
              </LoadingButton>
            </Box>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
}
