import { Auth } from '@aws-amplify/auth';
import { Backdrop, CircularProgress } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import OrganisationUsersContext from '../../../context/OrganisationUsersContext/OrganisationUsersContext';
import UserContext from '../../../context/UserContext';
import { OrganisationMember, Permission } from '../../../domain/Organisation';
import { useDialog } from '../../../hooks/useDialog/useDialog';
import { setGlobalError, setGlobalInfo } from '../../../redux/rootReducer';
import * as Api from '../../../services/Api';
import { ConfirmationDialog, ConfirmationDialogConfig } from '../../shared/ConfirmationDialog/ConfirmationDialog';
import OrganisationUsersTable from '../OrganisationUsersTable/OrganisationUsersTable';

interface Props {
  organisation: string;
}

export default function OrganisationUsers({ organisation }: Props) {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { users, setUsers, loading: usersLoading } = useContext(OrganisationUsersContext);
  const { user: currentUser } = useContext(UserContext);

  const { open, connector } = useDialog<ConfirmationDialogConfig>();

  const updateUserPermissions =
    (user: OrganisationMember, permission: Permission) => (ev: React.ChangeEvent<HTMLInputElement>) => {
      const newPermissions = new Set(user.permissions);
      if (ev.target.checked) {
        newPermissions.add(permission);
      } else {
        newPermissions.delete(permission);
      }

      setLoading(true);
      Api.updateUser(user.id, [...newPermissions], organisation)
        .then((updatedUser) => {
          setUsers(users.map((u) => (u.id === user.id ? updatedUser : u)));
          dispatch(setGlobalInfo(`Updated ${user.email}`));

          if (currentUser?.email === user.email) {
            Auth.currentAuthenticatedUser({ bypassCache: true });
          }
        })
        .catch((error) => {
          dispatch(setGlobalError(`Unable to update permissions for ${user.email}`, error));
        })
        .finally(() => setLoading(false));
    };

  const deleteUser = (user: OrganisationMember) => {
    return Api.deleteUser(user.id, organisation)
      .then(() => {
        setUsers(users.filter((u) => user.id !== u.id));
        dispatch(setGlobalInfo(`Deleted ${user.email}`));
      })
      .catch((error) => {
        dispatch(setGlobalError(`Unable to delete ${user.email}`, error));
      });
  };

  const askBeforeDeletingUser = (user: OrganisationMember) => {
    open({
      title: 'Delete user',
      text: `Are you sure you want to delete ${user.email}?`,
    }).then(() => deleteUser(user));
  };

  return (
    <>
      <Backdrop open={loading || usersLoading} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress data-testid="spinner" />
      </Backdrop>
      <OrganisationUsersTable
        users={users}
        updateUserPermissions={updateUserPermissions}
        deleteUser={askBeforeDeletingUser}
      />
      <ConfirmationDialog connector={connector} />
    </>
  );
}
