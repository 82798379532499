import React, { useState } from 'react';

import { FormControl, FormHelperTextProps, Stack } from '@mui/material';
import PasswordField from '../../../../components/shared/PasswordField/PasswordField';
import TextField from '../../../../components/shared/TextField/TextField';
import { FieldError, FieldValues, useForm } from 'react-hook-form';
import { Auth } from '@aws-amplify/auth';
import { useDispatch } from 'react-redux';
import { setGlobalError } from '../../../../redux/rootReducer';
import { friendlierRegistrationError } from '../../../../util/errors/errors';
import NhsNetEmailField from '../../../shared/NhsNetEmailField/NhsNetEmailField';
import { LoadingButton } from '@mui/lab';

interface Props {
  onRegister: () => void;
}

/*
 * - At least one digit
 * - At least one lowercase letter
 * - At least one uppercase letter
 * - At least one symbol of - ^ $ * . [ ] { } ( ) ? " ! @ # % & / \ , > < ' : ; | _ ~ ` + =
 * - At least 8 characters
 */
const STRONG_PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[-^$*.\[\]{}()?"!@#%&/\\,><':;|_~`+=])(?=.{8,}).*$/;

export default function RegistrationForm({ onRegister }: Props) {
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const updateErrorMessage = (errorMessage: null | string) => {
    dispatch(setGlobalError(errorMessage));
  };

  const register = ({ email, firstName, lastName, role, password, passwordConfirmation }: FieldValues) => {
    if (password !== passwordConfirmation) {
      updateErrorMessage('The passwords do not match');
      reset({
        email,
        firstName,
        lastName,
        role,
        password: '',
        passwordConfirmation: '',
      });

      return;
    }

    setSubmitting(true);
    Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        given_name: firstName,
        family_name: lastName,
        'custom:role': role,
      },
    })
      .then(onRegister)
      .catch((err) => {
        updateErrorMessage(`Unable to register: ${friendlierRegistrationError(err.message)}`);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <form>
      <FormControl sx={{ rowGap: '24px', width: '290px' }}>
        <NhsNetEmailField
          id="email"
          name={'email'}
          control={control}
          error={errors.email as FieldError}
          rules={{
            required: 'E-mail is required',
          }}
          label="E-mail"
          autoFocus={true}
          required
        />

        <Stack direction="row" spacing={2}>
          <TextField
            id="firstName"
            name={'firstName'}
            control={control}
            error={errors.firstName as FieldError}
            rules={{
              required: 'First name is required',
              invalid: 'Please enter a valid name',
            }}
            label="First name"
            required
          />

          <TextField
            id="lastName"
            name={'lastName'}
            control={control}
            error={errors.lastName as FieldError}
            rules={{
              required: 'Last name is required',
              invalid: 'Please enter a valid name',
            }}
            label="Last name"
            required
          />
        </Stack>

        <TextField
          id="role"
          name={'role'}
          control={control}
          error={errors.role as FieldError}
          rules={{
            required: 'Role is required',
          }}
          label="Role"
          required
        />

        <PasswordField
          id="password"
          name={'password'}
          control={control}
          error={errors.password as FieldError}
          label="Password"
          required
          rules={{
            required: 'Password is required',
            pattern: {
              value: STRONG_PASSWORD_REGEX,
              message: (
                <div>
                  Your password must contain:
                  <ul>
                    <li>At least 8 characters</li>
                    <li>At least 1 uppercase letter, 1 lowercase letter, 1 digit and 1 symbol</li>
                  </ul>
                </div>
              ),
            },
          }}
          FormHelperTextProps={
            {
              component: 'div',
            } as Partial<FormHelperTextProps>
          }
        />

        <PasswordField
          id="passwordConfirmation"
          name="passwordConfirmation"
          control={control}
          error={errors.passwordConfirmation as FieldError}
          label="Confirm password"
          required
          rules={{ required: 'Password confirmation is required' }}
        />

        <LoadingButton data-testid="register" variant="contained" loading={submitting} onClick={handleSubmit(register)}>
          Register
        </LoadingButton>
      </FormControl>
    </form>
  );
}
