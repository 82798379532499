import { API } from '@aws-amplify/api';
import { Auth } from '@aws-amplify/auth';
import axios from 'axios';
import { Application } from '../domain/Application';
import { DownloadUrls } from '../domain/DownloadUrls';
import { Invitation } from '../domain/Invitation';
import { OptimisationConfig } from '../domain/Optimisation';
import { OrganisationMember } from '../domain/Organisation';
import { errorTolog, newLogger } from '../logging/logger';
import { ApiError } from './ApiError';

const TIMEOUT = 10_000;

const logger = newLogger('Api');

async function getIdToken(): Promise<string> {
  const session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
}

function handleApiError(error: unknown) {
  if (axios.isAxiosError(error) && error.config) {
    const { method, url, headers } = error.config;
    const traceId = headers?.['X-Trace-Id']?.toString();

    logger.error(`${method?.toUpperCase()} ${url}: ${error.response?.status ?? error.message}`, {
      error: errorTolog(error),
      traceId,
    });

    let errorDescription = (error.response?.data as { error: string })?.error ?? error.message;
    if (error.message === `timeout of ${TIMEOUT}ms exceeded`) {
      errorDescription = 'Please confirm that you are on the correct network and try again.';
    }

    throw new ApiError(errorDescription, { cause: error, traceId });
  } else if (error instanceof Error) {
    logger.error(error.message, {
      error: errorTolog(error),
    });

    throw new ApiError(error.message, { cause: error });
  } else {
    logger.error(String(error), {
      error: errorTolog(error),
    });

    throw new ApiError(String(error), {
      cause: {
        message: String(error),
        name: 'n/a',
      },
    });
  }
}

export async function loadUsers(organisation: string): Promise<OrganisationMember[]> {
  const idToken = await getIdToken();

  return API.get('cftest', `/organisations/${organisation}/users`, {
    headers: {
      Authorization: idToken,
    },
    timeout: TIMEOUT,
  }).catch(handleApiError);
}

export async function updateUser(
  userId: string,
  newPermissions: string[],
  organisation: string
): Promise<OrganisationMember> {
  const idToken = await getIdToken();

  return API.post('cftest', `/organisations/${organisation}/users/${userId}`, {
    headers: {
      Authorization: idToken,
    },
    body: { permissions: newPermissions },
    timeout: TIMEOUT,
  }).catch(handleApiError);
}

export async function deleteUser(userId: string, organisation: string): Promise<unknown> {
  const idToken = await getIdToken();

  return API.del('cftest', `/organisations/${organisation}/users/${userId}`, {
    headers: {
      Authorization: idToken,
    },
    timeout: TIMEOUT,
  }).catch(handleApiError);
}

export async function loadOrganisationInvitations(organisation: string): Promise<Invitation[]> {
  const idToken = await getIdToken();

  return API.get('cftest', `/organisations/${organisation}/invitations`, {
    headers: {
      Authorization: idToken,
    },
    timeout: TIMEOUT,
  }).catch(handleApiError);
}

export async function invite(email: string, organisation: string): Promise<Invitation> {
  const idToken = await getIdToken();

  return API.post('cftest', `/organisations/${organisation}/invitations`, {
    headers: {
      Authorization: idToken,
    },
    body: {
      email,
    },
    timeout: TIMEOUT,
  }).catch(handleApiError);
}

export async function deleteInvitation(email: string, organisation: string) {
  const idToken = await getIdToken();

  return API.del('cftest', `/organisations/${organisation}/invitations/${email}`, {
    headers: {
      Authorization: idToken,
    },
    timeout: TIMEOUT,
  }).catch(handleApiError);
}

export async function loadUserInvitations(): Promise<Invitation[]> {
  const idToken = await getIdToken();

  return API.get('cftest', '/organisations/invitations', {
    headers: {
      Authorization: idToken,
    },
    timeout: TIMEOUT,
  }).catch(handleApiError);
}

export async function acceptInvitation(organisation: string): Promise<OrganisationMember> {
  const idToken = await getIdToken();

  return API.patch('cftest', `/organisations/${organisation}/invitations`, {
    headers: {
      Authorization: idToken,
    },
    body: {
      accept: true,
    },
    timeout: TIMEOUT,
  }).catch(handleApiError);
}

export async function declineInvitation(organisation: string): Promise<unknown> {
  const idToken = await getIdToken();

  return API.patch('cftest', `/organisations/${organisation}/invitations`, {
    headers: {
      Authorization: idToken,
    },
    body: {
      accept: false,
    },
    timeout: TIMEOUT,
  }).catch(handleApiError);
}

export async function loadOrganisationApplications(organisation: string): Promise<Application[]> {
  const idToken = await getIdToken();

  return API.get('cftest', `/organisations/${organisation}/applications`, {
    headers: {
      Authorization: idToken,
    },
  }).catch(handleApiError);
}

export async function loadUserApplications(): Promise<Application[]> {
  const idToken = await getIdToken();

  return API.get('cftest', '/organisations/my/applications', {
    headers: {
      Authorization: idToken,
    },
    timeout: TIMEOUT,
  }).catch(handleApiError);
}

export async function apply(organisation: string): Promise<Application> {
  const idToken = await getIdToken();

  return API.post('cftest', `/organisations/${organisation}/applications`, {
    headers: {
      Authorization: idToken,
    },
    timeout: TIMEOUT,
  }).catch(handleApiError);
}

export async function withdrawApplication(application: Application): Promise<Application> {
  const idToken = await getIdToken();

  return API.patch('cftest', `/organisations/${application.organisation}/applications/${application.id}`, {
    headers: {
      Authorization: idToken,
    },
    body: {
      status: 'WITHDRAWN',
    },
    timeout: TIMEOUT,
  }).catch(handleApiError);
}

export async function approveApplication(application: Application): Promise<Application> {
  const idToken = await getIdToken();

  return API.patch('cftest', `/organisations/${application.organisation}/applications/${application.id}`, {
    headers: {
      Authorization: idToken,
    },
    body: {
      status: 'APPROVED',
    },
    timeout: TIMEOUT,
  }).catch(handleApiError);
}

export async function denyApplication(application: Application): Promise<Application> {
  const idToken = await getIdToken();

  return API.patch('cftest', `/organisations/${application.organisation}/applications/${application.id}`, {
    headers: {
      Authorization: idToken,
    },
    body: {
      status: 'DENIED',
    },
    timeout: TIMEOUT,
  }).catch(handleApiError);
}

export async function getDownloadUrls(): Promise<DownloadUrls> {
  const idToken = await getIdToken();

  return API.get('cftest', '/latest', {
    headers: {
      Authorization: idToken,
    },
    timeout: TIMEOUT,
  }).catch(handleApiError);
}

export async function loadOptimisationConfig(organisation: string): Promise<OptimisationConfig | null> {
  const idToken = await getIdToken();

  return API.get('qofopt', `/organisations/${organisation}/config`, {
    headers: {
      Authorization: idToken,
    },
    timeout: TIMEOUT,
  })
    .catch((error: unknown) => {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          return null;
        }
      }

      throw error;
    })
    .catch(handleApiError);
}

export async function saveOptimisationConfig(organisation: string, config: OptimisationConfig): Promise<void> {
  const idToken = await getIdToken();

  return API.put('qofopt', `/organisations/${organisation}/config`, {
    headers: {
      Authorization: idToken,
    },
    body: config,
    timeout: TIMEOUT,
  }).catch(handleApiError);
}
