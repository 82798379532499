import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useContext } from 'react';
import userContext from '../../../context/UserContext';
import { OrganisationMember, Permission } from '../../../domain/Organisation';

interface Props {
  users: OrganisationMember[];
  updateUserPermissions: (
    user: OrganisationMember,
    permission: Permission
  ) => (ev: React.ChangeEvent<HTMLInputElement>) => void;
  deleteUser: (user: OrganisationMember) => void;
}

function AdminRow({
  user,
  currentUser,
  updateUserPermissions,
  deleteUser,
}: { user: OrganisationMember; currentUser: string } & Pick<Props, 'updateUserPermissions' | 'deleteUser'>) {
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {user.email}
      </TableCell>
      <TableCell>
        <Stack direction="row" gap={2}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={user.permissions.includes('admin')}
                  onChange={updateUserPermissions(user, 'admin')}
                />
              }
              label="Admin"
              disabled={user.email === currentUser}
            />
          </FormGroup>
          {/* <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={user.permissions.includes('optimisation')}
                  onChange={updateUserPermissions(user, 'optimisation')}
                />
              }
              label="Optimisation"
            />
          </FormGroup> */}
        </Stack>
      </TableCell>
      <TableCell>
        <Button onClick={() => deleteUser(user)} disabled={user.email === currentUser}>
          Delete
        </Button>
      </TableCell>
    </TableRow>
  );
}

function UserRow({ user }: { user: OrganisationMember }) {
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {user.email}
      </TableCell>
      <TableCell>{user.permissions.join(', ')}</TableCell>
      <TableCell></TableCell>
    </TableRow>
  );
}

export default function OrganisationUsersTable({ users, updateUserPermissions, deleteUser }: Props) {
  const context = useContext(userContext);

  const currentUser = context!.user!.email;
  const isAdmin = users.some((user) => user.permissions.includes('admin') && user.email === currentUser);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>User e-mail</TableCell>
            <TableCell>Permissions</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users
            .sort((a, b) => a.email.localeCompare(b.email))
            .map((user) =>
              isAdmin ? (
                <AdminRow
                  key={user.email}
                  user={user}
                  currentUser={currentUser}
                  updateUserPermissions={updateUserPermissions}
                  deleteUser={deleteUser}
                />
              ) : (
                <UserRow key={user.email} user={user} />
              )
            )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
