import { datadogLogs, Logger } from '@datadog/browser-logs';
import { Config } from '../config/config';

export function init(config: Config) {
  datadogLogs.init({
    clientToken: config.datadog.clientToken,
    site: config.datadog.site,
    service: config.datadog.service,
    env: config.datadog.tags.env,
    forwardErrorsToLogs: true,
    sampleRate: 100,
    trackSessionAcrossSubdomains: true,
    useSecureSessionCookie: false,
    useCrossSiteSessionCookie: false,
  });
}

export function newLogger(name: string): Logger {
  const logger = datadogLogs.createLogger(name);
  logger.setHandler(['http', 'console']);
  return logger;
}

export function errorTolog(error: unknown): object {
  return error instanceof Error
    ? {
        ...error,
        message: error.message,
        name: error.name,
        stack: error.stack,
      }
    : { message: String(error) };
}
