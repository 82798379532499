import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { asSerialisable, SerialisableError } from './serialisableError';
import { RootState } from './store';

interface GlobalErrorState {
  message: string | null;
  error: SerialisableError | null;
}

const globalErrorSlice = createSlice({
  name: 'globalError',
  initialState: { message: null, error: null } as GlobalErrorState,
  reducers: {
    setGlobalError: (state: GlobalErrorState, action: PayloadAction<GlobalErrorState>) => {
      state.message = action.payload.message;
      state.error = action.payload.error;
    },
  },
});

const globalInfoSlice = createSlice({
  name: 'globalInfo',
  initialState: { value: null },
  reducers: {
    setGlobalInfo: (state, action) => {
      state.value = action.payload;
    },
  },
});

export default function createRootReducer() {
  return combineReducers({
    globalError: globalErrorSlice.reducer,
    globalInfo: globalInfoSlice.reducer,
  });
}

export function setGlobalError(message: string | null, error: unknown = null): PayloadAction<GlobalErrorState> {
  return globalErrorSlice.actions.setGlobalError({ message, error: asSerialisable(error) });
}

export const selectGlobalError = (state: RootState) => state.globalError;

export const { setGlobalInfo } = globalInfoSlice.actions;
export const selectGlobalInfo = (state: RootState) => state.globalInfo.value;
