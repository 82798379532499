import { AxiosRequestConfig } from 'axios';

export function traceIdInterceptor(config: AxiosRequestConfig) {
  if (!config.headers) {
    config.headers = {};
  }

  config.headers['X-Trace-Id'] = config.headers['X-Trace-Id'] ?? crypto.randomUUID();

  return config;
}
