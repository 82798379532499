import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Application } from '../../../domain/Application';
import { useDialog } from '../../../hooks/useDialog/useDialog';
import { setGlobalError, setGlobalInfo } from '../../../redux/rootReducer';
import * as Api from '../../../services/Api';
import { ApplyDialog } from '../ApplyDialog/ApplyDialog';

interface Props {
  compact?: boolean;
  onNewApplication: (application: Application) => void;
}

export function ApplicationRequest({ compact, onNewApplication }: Props) {
  const dispatch = useDispatch();
  const { open, connector } = useDialog<{}, string>();

  const onApply = (organisation: string) =>
    Api.apply(organisation)
      .then((application) => {
        onNewApplication(application);
        dispatch(setGlobalInfo(`Applied to join ${organisation}`));
      })
      .catch((error) => {
        dispatch(setGlobalError(`Unable to apply to ${organisation}`, error));
        throw error;
      });

  const openApplyDialog = () => {
    open({}).then((organisation) => onApply(organisation.trim()));
  };

  return (
    <>
      {compact ? (
        <Tooltip title="Join new organisation...">
          <IconButton color="primary" onClick={openApplyDialog}>
            <AddCircleIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Button variant="contained" onClick={openApplyDialog} startIcon={<AddCircleIcon />}>
          Join new organisation...
        </Button>
      )}

      <ApplyDialog connector={connector} />
    </>
  );
}
