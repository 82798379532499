import { List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { UserMembership } from '../../../domain/User';

interface Props {
  organisations: UserMembership[];
}

export default function OrganisationList({ organisations }: Props) {
  if (organisations.length === 0) {
    return <Typography variant="body1">You do not belong to any organisation yet</Typography>;
  }

  return (
    <>
      <Typography variant="body1">You belong to the following organisations:</Typography>
      <List sx={{ maxWidth: 360 }}>
        {organisations
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((organisation) => (
            <ListItem key={organisation.name} component={Link} to={`/organisations/${organisation.name}`}>
              <ListItemButton>
                <ListItemText primary={organisation.name} secondary={organisation.permissions.join(', ')} />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </>
  );
}
