import { Auth, CognitoUser } from '@aws-amplify/auth';
import { LoadingButton } from '@mui/lab';
import { Box, FormControl } from '@mui/material';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { ChallengeName, CognitoUserSession } from 'amazon-cognito-identity-js';
import React, { useState } from 'react';
import { FieldError, FieldValues, useForm } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { parseIdToken, User } from '../../../../domain/User';
import { setGlobalError } from '../../../../redux/rootReducer';
import EmailField from '../../../shared/EmailField/EmailField';
import PasswordField from '../../../shared/PasswordField/PasswordField';

export interface ExtendedCognitoUser extends CognitoUser {
  username: string;
  attributes: {
    email: string;
    given_name: string;
    family_name: string;
    'custom:role': string;
  };
  signInUserSession: CognitoUserSession;
  challengeName?: ChallengeName;
}

interface Props {
  onLogin: (user: User) => void;
  onPasswordChangeRequest: (email: string, password: string) => void;
}

const LoginForm = ({ onLogin, onPasswordChangeRequest }: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const updateErrorMessage = (errorMessage: null | string) => {
    dispatch(setGlobalError(errorMessage));
  };

  const login = ({ email, password }: FieldValues) => {
    setSubmitting(true);
    Auth.signIn(email, password)
      .then((cognitoUser: ExtendedCognitoUser) => {
        if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
          onPasswordChangeRequest(email, password);
        } else {
          const user = {
            email: cognitoUser.attributes.email,
            firstName: cognitoUser.attributes?.given_name ?? '',
            lastName: cognitoUser.attributes?.family_name ?? '',
            role: cognitoUser.attributes?.['custom:role'] ?? '',
            organisations: parseIdToken(cognitoUser.signInUserSession.getIdToken().getJwtToken()),
            superUser: cognitoUser.attributes?.['custom:super_user'] == 'true',
          };
          onLogin(user);
        }
      })
      .catch((err) => {
        updateErrorMessage(`Unable to login: ${err.message}`);
        setSubmitting(false);
      });
  };

  return (
    <>
      <Typography variant="h5" gutterBottom>
        Sign In
      </Typography>

      <form>
        <FormControl sx={{ rowGap: '24px', width: '290px' }}>
          <EmailField
            id="email"
            name={'email'}
            control={control}
            error={errors.email as FieldError}
            rules={{
              required: 'E-mail is required',
              invalid: 'Please enter a valid e-mail',
            }}
            label="E-mail"
            required
            autoFocus={true}
          />

          <PasswordField
            id="password"
            name={'password'}
            control={control}
            error={errors.password as FieldError}
            label="Password"
            required
            rules={{ required: 'Password is required' }}
          />

          <Box sx={{ position: 'relative' }}>
            <LoadingButton
              data-testid="sign-in"
              variant="contained"
              loading={submitting}
              sx={{ width: '100%' }}
              onClick={handleSubmit(login)}
            >
              Sign In
            </LoadingButton>
          </Box>

          <Typography variant="body1">
            Forgot password? Click{' '}
            <Link component={RouterLink} to="/passwordreset">
              here
            </Link>{' '}
            to reset it.
          </Typography>
        </FormControl>
      </form>
    </>
  );
};

export default connect()(LoginForm);
