import { Button, Grid, List, ListItem, ListItemText, Stack } from '@mui/material';
import React from 'react';
import { Invitation } from '../../../domain/Invitation';

interface Props {
  invitations: Invitation[];
  onAccept: (organisation: string) => void;
  onDecline: (organisation: string) => void;
}

function formatDate(date: string): string {
  return new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(date));
}

export default function UserInvitationsList({ invitations, onAccept, onDecline }: Props) {
  return (
    <>
      <List>
        {invitations
          .sort((a, b) => a.organisation.localeCompare(b.organisation))
          .map((invitation) => (
            <ListItem key={invitation.organisation}>
              <Grid container spacing="8" alignItems={'center'}>
                <Grid item xs>
                  <ListItemText
                    primary={invitation.organisation}
                    secondary={
                      <>
                        <div>{`Invited by: ${invitation.invitedBy}`}</div>
                        <div>{`Expires at: ${formatDate(invitation.expiresAt)}`}</div>
                      </>
                    }
                    secondaryTypographyProps={{ component: 'div' }}
                  />
                </Grid>
                <Grid item xs="auto">
                  <Stack direction="row" spacing={1}>
                    <Button onClick={() => onAccept(invitation.organisation)} variant="contained">
                      Accept
                    </Button>
                    <Button onClick={() => onDecline(invitation.organisation)}>Decline</Button>
                  </Stack>
                </Grid>
              </Grid>
            </ListItem>
          ))}
      </List>
    </>
  );
}
