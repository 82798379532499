import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobalError, setGlobalError } from '../../../redux/rootReducer';

export default function GlobalErrorAlert() {
  const { message, error } = useSelector(selectGlobalError);

  const [open, setOpen] = useState(message !== null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (message) {
      setOpen(true);
    }
  }, [message]);

  const close = () => {
    setOpen(false);
  };

  let fullMessage = message;
  if (error?.name === 'Error') {
    fullMessage = `${message}: ${error.message}`;
  } else if (error?.name === 'ApiError') {
    fullMessage = `${message}: ${error.message}`;
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      TransitionProps={{
        onExited: () => {
          // Only unset the error after the snackbar disappears completely
          dispatch(setGlobalError(null));
        },
      }}
    >
      <Alert severity="error" variant="filled" onClose={close}>
        {fullMessage}
      </Alert>
    </Snackbar>
  );
}
