import { HashRouter } from 'react-router-dom';
import React from 'react';
import { Provider } from 'react-redux';
import { Store } from '../redux/store';
import App from './App';
import AmplifyProvider from './auth/AmplifyProvider/AmplifyProvider';
import Routes from './navigation/Routes/Routes';
import ConfigContext from '../context/ConfigContext';
import config from '../config/config';

type Props = {
  store: Store;
};

export function Root({ store }: Props) {
  return (
    <Provider store={store}>
      <HashRouter>
        <ConfigContext.Provider value={config}>
          <AmplifyProvider>
            <App>
              <Routes />
            </App>
          </AmplifyProvider>
        </ConfigContext.Provider>
      </HashRouter>
    </Provider>
  );
}
