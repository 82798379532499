import { Backdrop, Box, CircularProgress, Typography, Link, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalError } from '../../../redux/rootReducer';
import * as Api from '../../../services/Api';
import PendingUserApplicationsList from '../PendingUserApplicationsList/PendingUserApplicationsList';
import { Application } from '../../../domain/Application';
import { ConfirmationDialog, ConfirmationDialogConfig } from '../../shared/ConfirmationDialog/ConfirmationDialog';
import { useDialog } from '../../../hooks/useDialog/useDialog';
import { Link as RouterLink } from 'react-router-dom';
import { ApplicationRequest } from '../ApplicationRequest/ApplicationRequest';
import SidePanel from '../../shared/SidePanel/SidePanel';

export default function PendingUserApplicationsPanel() {
  const [loading, setLoading] = useState(true);
  const [pendingApplications, setPendingApplications] = useState<Application[]>([]);

  const dispatch = useDispatch();

  const { open, connector } = useDialog<ConfirmationDialogConfig>();

  useEffect(() => {
    Api.loadUserApplications()
      .then((newApplications) => {
        setPendingApplications(newApplications.filter((app) => app.status === 'PENDING'));
      })
      .catch((error) => {
        dispatch(setGlobalError('Unable to load applications for user', error));
      })
      .finally(() => setLoading(false));
  }, []);

  const onWithdraw = (application: Application) => {
    return Api.withdrawApplication(application)
      .then(() => {
        const updatedApplications = pendingApplications.filter((app) => app.id !== application.id);
        setPendingApplications(updatedApplications);
      })
      .catch((error) => {
        dispatch(setGlobalError(`Unable to withdraw the application to join ${application.organisation}`, error));
      });
  };

  const askBeforeWithdrawing = (application: Application) => {
    open({
      title: 'Withdraw application',
      text: `Are you sure you want to withdraw your application to join ${application.organisation}?`,
    }).then(() => onWithdraw(application));
  };

  const onNewApplication = (application: Application) => {
    setPendingApplications(pendingApplications.concat(application));
  };

  if (loading) {
    return (
      <Backdrop open={loading} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress data-testid="spinner" />
      </Backdrop>
    );
  }

  return (
    <>
      <SidePanel title="Pending applications">
        {pendingApplications.length === 0 ? (
          <>
            <Box ml={2} mt={2}>
              <Typography variant="body1">You have no pending applications.</Typography>
            </Box>
          </>
        ) : (
          <>
            <PendingUserApplicationsList applications={pendingApplications} onWithdraw={askBeforeWithdrawing} />
          </>
        )}

        <Box ml={2}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="body2" component="span">
              <Link to="/applications" component={RouterLink}>
                See all
              </Link>
            </Typography>

            <ApplicationRequest compact={true} onNewApplication={onNewApplication} />
          </Stack>
        </Box>
      </SidePanel>

      <ConfirmationDialog connector={connector} />
    </>
  );
}
