import React, { useEffect, useState } from 'react';
import * as Api from '../../services/Api';
import { Backdrop, CircularProgress, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { DownloadUrls } from '../../domain/DownloadUrls';
import DownloadIcon from '@mui/icons-material/Download';
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import SidePanel from '../shared/SidePanel/SidePanel';

function DownloadItem({ label, url }: { label: string; url: string }) {
  return (
    <ListItem disablePadding>
      <ListItemButton component="a" href={url}>
        <ListItemIcon>
          <DownloadIcon />
        </ListItemIcon>
        <ListItemText primary={label} />
      </ListItemButton>
    </ListItem>
  );
}

function DownloadItemDisabled({ label }: { label: string }) {
  return (
    <ListItem disablePadding>
      <ListItemButton disabled>
        <ListItemIcon>
          <FileDownloadOffIcon />
        </ListItemIcon>
        <ListItemText
          primary={label}
          secondary="Could not find the latest version"
          secondaryTypographyProps={{ fontStyle: 'italic' }}
        />
      </ListItemButton>
    </ListItem>
  );
}

export default function DownloadsPanel() {
  const [downloadUrls, setDownloadUrls] = useState<DownloadUrls>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Api.getDownloadUrls()
      .then(setDownloadUrls)
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <Backdrop open={loading} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress data-testid="spinner" />
      </Backdrop>
    );
  }

  return (
    <SidePanel title="Downloads">
      <List>
        {downloadUrls.emis ? (
          <DownloadItem label="Proactive Monitoring Tool (EMIS Web)" url={downloadUrls.emis} />
        ) : (
          <DownloadItemDisabled label="Proactive Monitoring Tool (EMIS Web)" />
        )}
        {downloadUrls.tpp ? (
          <DownloadItem label="Proactive Monitoring Tool (TPP SystmOne)" url={downloadUrls.tpp} />
        ) : (
          <DownloadItemDisabled label="Proactive Monitoring Tool (TPP SystmOne)" />
        )}
      </List>
    </SidePanel>
  );
}
