import { Auth } from '@aws-amplify/auth';
import {
  Avatar,
  Button,
  ClickAwayListener,
  Divider,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import React, { useContext, useRef, useState } from 'react';
import UserContext from '../../../context/UserContext';

export const UserMenu = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const userContext = useContext(UserContext);

  const userInitials = () => {
    const { firstName, lastName } = userContext.user!;
    return firstName.charAt(0) + lastName.charAt(0);
  };

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Button ref={anchorRef} onClick={handleToggle}>
        <Avatar>{userContext.user ? userInitials() : ''}</Avatar>
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open}>
                  <MenuItem>
                    <Typography>Logged in as: {userContext?.user?.email ?? ''}</Typography>
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={() => Auth.signOut()}>Log Out</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
