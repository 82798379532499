/* eslint-disable @typescript-eslint/no-use-before-define */
import { ApiError } from '../services/ApiError';

export interface SerialisableError {
  name?: string;
  message: string;
  stack?: string;
  cause?: SerialisableError;
  traceId?: string;
}

export function asSerialisable(error: unknown): SerialisableError {
  if (error instanceof ApiError) {
    return asSerialisableApiError(error);
  } else if (error instanceof Error) {
    return asSerialisableError(error);
  } else if (error && typeof error === 'object') {
    return asSerialisableObject(error as Record<string, unknown>);
  } else {
    return {
      message: String(error),
    };
  }
}

function asSerialisableError(error: Error): SerialisableError {
  return {
    name: error.name,
    message: error.message,
    stack: error.stack,
  };
}

function asSerialisableApiError(error: ApiError): SerialisableError {
  return {
    name: error.name,
    message: error.message,
    stack: error.stack,
    cause: error.cause ? asSerialisable(error.cause) : undefined,
    traceId: error.traceId,
  };
}

function asSerialisableObject(error: Record<string, unknown>): SerialisableError {
  return {
    name: error.name ? String(error.name) : undefined,
    message: error.message ? String(error.message) : JSON.stringify(error),
    stack: error.stack ? String(error.stack) : undefined,
  };
}
