declare var CONFIG: string;

export interface Config {
  amplify: {
    region: string;
    auth: {
      userPoolId: string;
      userPoolWebClientId: string;
    };
    endpoints: {
      cftest: string;
      qofopt: string;
    };
  };
  datadog: DatadogConfig;
}

interface DatadogConfig {
  service: string;
  tags: Record<string, string>;
  site: string;
  clientToken: string;
}

export default JSON.parse(CONFIG) as Config;
