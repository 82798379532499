import { AppBar, Tab, Tabs, Toolbar } from '@mui/material';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UserMenu } from '../UserMenu/UserMenu';

export default function NavBar() {
  const location = useLocation();

  const tabs = [
    { value: 'home', label: 'Home', to: '/' },
    { value: 'applications', label: 'Applications', to: '/applications' },
  ];

  const currentTab = tabs.find((tab) => tab.to !== '/' && location.pathname.startsWith(tab.to)) || tabs[0];

  return (
    <AppBar position="relative">
      <Toolbar>
        <Tabs sx={{ flexGrow: 1 }} value={currentTab.value} textColor="inherit">
          {tabs.map((tab) => (
            <Tab key={tab.value} value={tab.value} label={tab.label} component={Link} to={tab.to} />
          ))}
        </Tabs>
        <UserMenu />
      </Toolbar>
    </AppBar>
  );
}
