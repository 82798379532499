import { Auth, CognitoUser } from '@aws-amplify/auth';
import { FormControl, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { ChallengeName, CognitoUserSession } from 'amazon-cognito-identity-js';
import React from 'react';
import { FieldError, FieldValues, useForm } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { parseIdToken, User } from '../../../../domain/User';
import { setGlobalError } from '../../../../redux/rootReducer';
import PasswordField from '../../../shared/PasswordField/PasswordField';

interface Props {
  email: string;
  password: string;
  onPasswordChanged: (user: User) => void;
  dispatch: Dispatch;
}

export interface ExtendedCognitoUser extends CognitoUser {
  username: string;
  challengeParam: {
    userAttributes: {
      email: string;
      given_name: string;
      family_name: string;
      'custom:role': string;
      'custom:super_user'?: string;
    };
  };
  signInUserSession: CognitoUserSession;
  challengeName?: ChallengeName;
}

const ChangePasswordForm = ({ email, password, onPasswordChanged }: Props) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const updateErrorMessage = (errorMessage: null | string) => {
    dispatch(setGlobalError(errorMessage));
  };

  const changePassword = ({ newPassword, newPasswordConfirmation }: FieldValues) => {
    if (newPassword !== newPasswordConfirmation) {
      updateErrorMessage('The passwords do not match');
      reset({
        newPassword: '',
        newPasswordConfirmation: '',
      });
      return;
    }

    Auth.signIn(email, password)
      .then((challengedUser: ExtendedCognitoUser) => Auth.completeNewPassword(challengedUser, newPassword))
      .then((cognitoUser: ExtendedCognitoUser) => {
        const attributes = cognitoUser.challengeParam.userAttributes;
        const user = {
          email: attributes.email,
          firstName: attributes?.given_name ?? '',
          lastName: attributes?.family_name ?? '',
          role: attributes?.['custom:role'] ?? '',
          organisations: parseIdToken(cognitoUser.signInUserSession.getIdToken().getJwtToken()),
          superUser: attributes?.['custom:super_user'] == 'true',
        };

        onPasswordChanged(user);
      })
      .catch((error) => {
        updateErrorMessage(`Unable to change password: ${error.message}`);
      });
  };

  return (
    <form>
      <Typography variant="h5" gutterBottom>
        Change your password
      </Typography>

      <FormControl sx={{ rowGap: '24px', width: '290px' }}>
        <PasswordField
          id="newPassword"
          name="newPassword"
          control={control}
          error={errors.newPassword as FieldError}
          label="New password"
          autoFocus={true}
          required
          rules={{ required: 'New password is required' }}
        />

        <PasswordField
          id="newPasswordConfirmation"
          name="newPasswordConfirmation"
          control={control}
          error={errors.newPasswordConfirmation as FieldError}
          label="Confirm new password"
          required
          rules={{ required: 'Password confirmation is required' }}
        />

        <Button
          data-testid="update-password"
          variant="contained"
          color="primary"
          onClick={handleSubmit(changePassword)}
        >
          Update password
        </Button>
      </FormControl>
    </form>
  );
};

export default connect()(ChangePasswordForm);
