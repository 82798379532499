export function friendlierRegistrationError(message: string): string {
  let result;
  if (message.match(/PreSignUp failed with error .* is not allowed to register with ProactiveMonitoring/)) {
    return 'Only NHS users can register with ProactiveMonitoring';
  } else if (
    message.match(/Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern/)
  ) {
    return 'Password must contain at least 8 characters';
  } else if ((result = message.match(/Password did not conform with policy: (?<policy>.*)/))) {
    return result.groups.policy;
  } else {
    return message;
  }
}
