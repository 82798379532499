import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalError } from '../../../redux/rootReducer';
import * as Api from '../../../services/Api';
import UserApplicationsTable from '../UserApplicationsTable/UserApplicationsTable';
import { Application } from '../../../domain/Application';
import { ConfirmationDialog, ConfirmationDialogConfig } from '../../shared/ConfirmationDialog/ConfirmationDialog';
import { useDialog } from '../../../hooks/useDialog/useDialog';
import { ApplicationRequest } from '../ApplicationRequest/ApplicationRequest';

export default function UserApplications() {
  const [loading, setLoading] = useState(true);
  const [applications, setApplications] = useState<Application[]>([]);

  const dispatch = useDispatch();

  const { open, connector } = useDialog<ConfirmationDialogConfig>();

  useEffect(() => {
    Api.loadUserApplications()
      .then((newApplications) => {
        setApplications(newApplications);
      })
      .catch((error) => {
        dispatch(setGlobalError('Unable to load applications for user', error));
      })
      .finally(() => setLoading(false));
  }, []);

  const onWithdraw = (application: Application) => {
    return Api.withdrawApplication(application)
      .then((updatedApplication) => {
        const updatedApplications = applications.map((app) => {
          if (app.status === 'PENDING' && app.id === application.id) {
            return updatedApplication;
          } else {
            return app;
          }
        });
        setApplications(updatedApplications);
      })
      .catch((error) => {
        dispatch(setGlobalError(`Unable to withdraw the application to join ${application.organisation}`, error));
      });
  };

  const askBeforeWithdrawing = (application: Application) => {
    open({
      title: 'Withdraw application',
      text: `Are you sure you want to withdraw your application to join ${application.organisation}?`,
    }).then(() => onWithdraw(application));
  };

  const onNewApplication = (application: Application) => {
    setApplications(applications.concat(application));
  };

  if (loading) {
    return (
      <Backdrop open={loading} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress data-testid="spinner" />
      </Backdrop>
    );
  }

  return (
    <>
      {applications.length === 0 ? (
        <Typography variant="body1">You have no applications.</Typography>
      ) : (
        <>
          <Typography variant="body1">You applied to the following organisations:</Typography>
          <UserApplicationsTable applications={applications} onWithdraw={askBeforeWithdrawing} />
        </>
      )}

      <Box mt={2} textAlign="right">
        <ApplicationRequest onNewApplication={onNewApplication} />
      </Box>

      <ConfirmationDialog connector={connector} />
    </>
  );
}
