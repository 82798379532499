import { Chip } from '@mui/material';
import React from 'react';
import { ApplicationStatus } from '../../../domain/Application';

function formatStatus(status: ApplicationStatus): { label: string; color?: 'error' | 'info' | 'success' } {
  switch (status) {
    case 'PENDING':
      return { label: 'Pending', color: 'info' };
    case 'WITHDRAWN':
      return { label: 'Withdrawn' };
    case 'APPROVED':
      return { label: 'Approved', color: 'success' };
    case 'DENIED':
      return { label: 'Denied', color: 'error' };
  }
}

export function ApplicationStatusChip({ status }: { status: ApplicationStatus }) {
  return <Chip {...formatStatus(status)} />;
}
