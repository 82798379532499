import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { Application } from '../../../domain/Application';
import { ApplicationStatusChip } from '../ApplicationStatusChip/ApplicationStatusChip';

interface Props {
  applications: Application[];
  onWithdraw: (application: Application) => void;
}

export default function UserApplicationsTable({ applications, onWithdraw }: Props) {
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Organisation</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Created at</TableCell>
              <TableCell>Reviewer</TableCell>
              <TableCell>Updated at</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[...applications]
              .sort((a, b) => -a.createdAt.localeCompare(b.createdAt))
              .map((application) => (
                <TableRow key={application.id}>
                  <TableCell>{application.organisation}</TableCell>
                  <TableCell>
                    <ApplicationStatusChip status={application.status} />
                  </TableCell>
                  <TableCell>
                    {new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'short' }).format(
                      new Date(application.createdAt)
                    )}
                  </TableCell>
                  <TableCell>{application.reviewer}</TableCell>
                  <TableCell>
                    {application.updatedAt &&
                      new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'short' }).format(
                        new Date(application.updatedAt)
                      )}
                  </TableCell>
                  <TableCell>
                    {application.status === 'PENDING' && (
                      <Button onClick={() => onWithdraw(application)}>Withdraw</Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
