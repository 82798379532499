import { Backdrop, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalError, setGlobalInfo } from '../../../redux/rootReducer';
import * as Api from '../../../services/Api';
import UserInvitationsList from '../UserInvitationsList/UserInvitationsList';
import { Invitation } from '../../../domain/Invitation';
import { Auth } from '@aws-amplify/auth';
import { ConfirmationDialog, ConfirmationDialogConfig } from '../../shared/ConfirmationDialog/ConfirmationDialog';
import { useDialog } from '../../../hooks/useDialog/useDialog';
import { useNavigate } from 'react-router-dom';
import PrimarySidePanel from '../../shared/PrimarySidePanel/PrimarySidePanel';

export default function UserInvitationsPanel() {
  const [loading, setLoading] = useState(true);
  const [invitations, setInvitations] = useState<Invitation[]>([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { open, connector } = useDialog<ConfirmationDialogConfig>();

  useEffect(() => {
    Api.loadUserInvitations()
      .then((newInvitations) => {
        setInvitations(newInvitations);
      })
      .catch((error) => {
        dispatch(setGlobalError('Unable to load pending invitations for user', error));
      })
      .finally(() => setLoading(false));
  }, []);

  const onAccept = (organisation: string) => {
    setLoading(true);
    Api.acceptInvitation(organisation)
      .then(() => {
        Auth.currentAuthenticatedUser({ bypassCache: true });
        dispatch(setGlobalInfo(`Joined ${organisation}`));
        const remainingInvitations = invitations.filter((invitation) => invitation.organisation !== organisation);
        if (remainingInvitations.length > 0) {
          setInvitations(remainingInvitations);
          setLoading(false);
        } else {
          navigate(`/organisations/${organisation}`);
        }
      })
      .catch((error) => {
        dispatch(setGlobalError(`Unable to accept the invitation to join ${organisation}`, error));
        setLoading(false);
      });
  };

  const onDecline = (organisation: string) => {
    return Api.declineInvitation(organisation)
      .then(() => {
        dispatch(setGlobalInfo(`Declined the invitation to join ${organisation}`));
        setInvitations(invitations.filter((invitation) => invitation.organisation !== organisation));
      })
      .catch((error) => {
        dispatch(setGlobalError(`Unable to decline the invitation to join ${organisation}`, error));
      });
  };

  const askBeforeDeclining = (organisation: string) => {
    open({
      title: 'Decline invitation',
      text: `Are you sure you want to decline the invitation for ${organisation}?`,
    }).then(() => onDecline(organisation));
  };

  if (loading) {
    return (
      <Backdrop open={loading} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress data-testid="spinner" />
      </Backdrop>
    );
  }

  return (
    <>
      {invitations.length > 0 && (
        <PrimarySidePanel title="Pending invitations">
          <UserInvitationsList invitations={invitations} onAccept={onAccept} onDecline={askBeforeDeclining} />
        </PrimarySidePanel>
      )}

      <ConfirmationDialog connector={connector} />
    </>
  );
}
