import { Backdrop, CircularProgress, FormControl, FormControlLabel } from '@mui/material';
import { Box, styled } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as domain from '../../../domain/Optimisation';
import { setGlobalError } from '../../../redux/rootReducer';
import * as Api from '../../../services/Api';
import { LoadingSlider } from '../../shared/LoadingSlider/LoadingSlider';

const ALL = 'ALL';
const ALL_VALUE = 225;

const StyledFormControlLabel = styled(FormControlLabel)({
  '& .slider-label': {
    minWidth: 250,
  },
});

interface Props {
  organisation: string;
  enabled: boolean;
}

export function OptimisationConfig({ organisation, enabled }: Props) {
  const marks = [
    {
      value: 0,
      label: 'None',
    },
    {
      value: 25,
    },
    {
      value: 50,
      label: '50',
    },
    {
      value: 75,
    },
    {
      value: 100,
      label: '100',
    },
    {
      value: 125,
    },
    {
      value: 150,
      label: '150',
    },
    {
      value: 175,
    },
    {
      value: 200,
      label: '200',
    },
    {
      value: ALL_VALUE,
      label: 'All',
    },
  ];

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [config, setConfig] = useState<domain.OptimisationConfig | null>(null);

  const dispatch = useDispatch();

  const maxRecommendationsToSlider: (maxRecommendations: number | string) => number = (
    maxRecommendations: number | string
  ) => (maxRecommendations === 'ALL' ? ALL_VALUE : (maxRecommendations as number));

  const sliderToMaxRecommendations = (maxRecommendations: number): number | string =>
    maxRecommendations === ALL_VALUE ? ALL : maxRecommendations;

  const saveConfig = (newConfig: domain.OptimisationConfig) => {
    setSaving(true);
    Api.saveOptimisationConfig(organisation, newConfig)
      .catch((error) => {
        dispatch(setGlobalError('Unable to save the changes', error));
      })
      .finally(() => {
        setSaving(false);
      });
  };

  useEffect(() => {
    Api.loadOptimisationConfig(organisation)
      .then((newConfig) => setConfig(newConfig ?? { maxRecommendations: 0 }))
      .catch((error) => {
        dispatch(setGlobalError('Unable to load the optimisation configuration', error));
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <Backdrop open={loading} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress data-testid="spinner" />
      </Backdrop>
    );
  }

  return (
    <Box maxWidth={700}>
      <FormControl fullWidth>
        <StyledFormControlLabel
          control={
            <LoadingSlider
              aria-label="Maximum recommendations"
              marks={marks}
              min={0}
              max={ALL_VALUE}
              step={null}
              value={maxRecommendationsToSlider(config?.maxRecommendations ?? 0)}
              onChange={(_ev, value) =>
                setConfig({ ...config, maxRecommendations: sliderToMaxRecommendations(value as number) })
              }
              onChangeCommitted={(_ev, value) =>
                saveConfig({ ...config, maxRecommendations: sliderToMaxRecommendations(value as number) })
              }
              loading={saving}
              disabled={!enabled || saving}
            />
          }
          label="Maximum recommendations:"
          labelPlacement="start"
          classes={{
            label: 'slider-label',
          }}
        />
      </FormControl>
    </Box>
  );
}
