import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext } from 'react';
import NavBar from '../../components/navigation/NavBar/NavBar';
import OrganisationUsers from '../../components/organisations/OrganisationUsers/OrganisationUsers';
import OrganisationApplications from '../../components/organisations/OrganisationApplications/OrganisationApplications';
import userContext from '../../context/UserContext';
import { isAdminAt } from '../../domain/User';
import OrganisationInvitations from '../../components/organisations/OrganisationInvitations/OrganisationInvitations';
import OrganisationUsersContext, {
  OrganisationUsersContextProvider,
} from '../../context/OrganisationUsersContext/OrganisationUsersContext';
import { useMatch } from 'react-router-dom';
import { OptimisationConfig } from '../../components/organisations/OptimisationConfig/OptimisationConfig';

export default function OrganisationPage() {
  const {
    params: { name },
  } = useMatch('/organisations/:name')!;
  const organisation = name!;

  const context = useContext(userContext);
  const isAdmin = isAdminAt(context.user!, organisation);
  const isSuperUser = context.user?.superUser ?? false;

  return (
    <>
      <NavBar />

      <Box m={2}>
        <Typography variant="h4">{organisation}</Typography>
      </Box>

      <OrganisationUsersContextProvider organisation={organisation}>
        <Box m={2}>
          <Typography variant="h5">Members</Typography>
          <OrganisationUsers organisation={organisation} />
        </Box>

        {isAdmin && (
          <>
            <Box m={2} mt={6}>
              <Typography variant="h5" gutterBottom>
                Pending invitations
              </Typography>
              <OrganisationInvitations organisation={organisation} />
            </Box>

            <Box m={2} mt={6}>
              <Typography variant="h5" gutterBottom>
                Applications
              </Typography>
              <OrganisationUsersContext.Consumer>
                {({ refresh }) => (
                  // Refresh users when an application is approved
                  <OrganisationApplications organisation={organisation} onApplicationApproved={refresh} />
                )}
              </OrganisationUsersContext.Consumer>
            </Box>

            <Box m={2} mt={6}>
              <Typography variant="h5" gutterBottom>
                Optimisation configuration
              </Typography>

              <OptimisationConfig organisation={organisation} enabled={isSuperUser} />
            </Box>
          </>
        )}
      </OrganisationUsersContextProvider>
    </>
  );
}
