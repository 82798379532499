import { LoadingButton } from '@mui/lab';
import { Dialog, DialogTitle, DialogContent, FormControl, DialogActions, Button, Box, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FieldError, FieldValues, useForm } from 'react-hook-form';
import { UseDialogConnector } from '../../../hooks/useDialog/useDialog';
import EmailField from '../../shared/EmailField/EmailField';

export function InviteDialog({ connector }: { connector: UseDialogConnector<{}, string> }) {
  const [open, setOpen] = useState(connector.config !== null);
  const [submitting, setSubmitting] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  useEffect(() => {
    if (connector.config) {
      setOpen(true);
    }
  }, [connector.config]);

  const close = () => {
    reset({ email: '' });
    setOpen(false);
  };

  const invite = ({ email }: FieldValues) => {
    setSubmitting(true);

    return connector
      .submit(email)
      .then(close)
      .catch(() => {})
      .finally(() => {
        setSubmitting(false);
      });
  };

  if (!connector.config) {
    return <></>;
  }

  return (
    <>
      <Dialog open={open} onClose={close}>
        <DialogTitle>Invite user</DialogTitle>
        <DialogContent>
          <form>
            <FormControl sx={{ marginTop: '8px', width: '290px' }}>
              <EmailField
                id="email"
                name={'email'}
                control={control}
                error={errors.email as FieldError}
                rules={{
                  required: 'E-mail is required',
                  invalid: 'Please enter a valid e-mail',
                }}
                label="E-mail"
                required
                autoFocus={open}
              />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={1}>
            <Button variant="outlined" onClick={close}>
              Cancel
            </Button>
            <Box sx={{ position: 'relative' }}>
              <LoadingButton
                onClick={handleSubmit(invite)}
                variant="contained"
                loading={submitting}
                data-testid="invite"
              >
                Invite
              </LoadingButton>
            </Box>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
}
