import React, { useContext } from 'react';
import { Route, Routes as RouterRoutes } from 'react-router-dom';
import UserContext from '../../../context/UserContext';
import { URLS } from '../../../util/urls';
import PasswordReset from '../../../pages/resetpassword/ResetPasswordPage';
import LoginPage from '../../../pages/login/LoginPage';
import HomePage from '../../../pages/home/HomePage';
import OrganisationPage from '../../../pages/organisations/OrganisationPage';
import RegistrationPage from '../../../pages/registration/RegistrationPage';
import { Backdrop, CircularProgress } from '@mui/material';
import ApplicationsPage from '../../../pages/applications/ApplicationsPage';

const LoggedOutRoutes: React.FC = () => {
  return (
    <RouterRoutes>
      <Route path={URLS.LOGIN} element={<LoginPage />} />
      <Route path={URLS.REGISTER} element={<RegistrationPage />} />
      <Route path={URLS.PASSWORD_RESET} element={<PasswordReset />} />
      <Route path="*" element={<LoginPage />} />
    </RouterRoutes>
  );
};

const LoggedInRoutes: React.FC = () => {
  return (
    <RouterRoutes>
      <Route path="/" element={<HomePage />} />
      <Route path="/organisations/:name" element={<OrganisationPage />} />
      <Route path="/applications" element={<ApplicationsPage />} />
    </RouterRoutes>
  );
};

export default function Routes() {
  const { user, loaded } = useContext(UserContext);
  const isAuthenticated = user && user.email;

  if (!loaded) {
    return (
      <Backdrop open={!loaded} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress data-testid="spinner" />
      </Backdrop>
    );
  }

  return <>{isAuthenticated ? <LoggedInRoutes /> : <LoggedOutRoutes />}</>;
}
