import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { UseDialogConnector } from '../../../hooks/useDialog/useDialog';

export interface ConfirmationDialogConfig {
  title: string;
  text: string;
}

export function ConfirmationDialog({ connector }: { connector: UseDialogConnector<ConfirmationDialogConfig> }) {
  const [open, setOpen] = useState(connector.config !== null);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (connector.config) {
      setOpen(true);
    }
  }, [connector.config]);

  const close = () => {
    setOpen(false);
  };

  if (!connector.config) {
    return <></>;
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={close}
        TransitionProps={{
          onExited: () => {
            // Called only when the dialog disappears completely
            connector.close();
          },
        }}
      >
        <DialogTitle>{connector.config.title}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">{connector.config?.text}</Typography>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={1}>
            <Button variant="outlined" onClick={close}>
              No
            </Button>
            <Box sx={{ position: 'relative' }}>
              <LoadingButton
                onClick={() => {
                  setSubmitting(true);
                  connector
                    .submit()
                    .then(close)
                    .catch(() => {})
                    .finally(() => setSubmitting(false));
                }}
                variant="contained"
                loading={submitting}
                data-testid="confirm"
              >
                Yes
              </LoadingButton>
            </Box>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
}
