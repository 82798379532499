import React from 'react';
import { User } from '../domain/User';

export interface UserContext {
  user: User | null;
  updateCurrentUser: (user?: User) => void;
  loaded: boolean;
}

const userContext = React.createContext<UserContext>({
  user: null,
  updateCurrentUser: () => {},
  loaded: false,
});

export default userContext;
